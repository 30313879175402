import isEmpty from 'lodash/isEmpty';
import useSWR from 'swr-0-5-6';
import urlcat from 'urlcat';
import { apirc } from '~/configs/apirc';
import { VirtualExchange } from '~/modules/SDK/Trade/tradeAPI';
const prefixUrl = apirc.trade.virtualExchangeURL;
/** @private 用 Linked name 回推連結到的 accountId */
export const useVirtualAccountLink = (linkedName) => {
    const url = urlcat(`${prefixUrl}/${VirtualExchange.linkedNameToAccountId}`, { linkedName });
    const res = useSWR(!isEmpty(linkedName) ? url : null, fetcherWithFixedToken, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    const result = res.data?.map(item => item.accountId);
    return result?.toString();
};
export const useVirtualAccountAllLinkedNames = () => {
    const url = `${prefixUrl}/${VirtualExchange.linkedNameToAccountId}`;
    const res = useSWR(url, fetcherWithFixedToken, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    const result = res.data ?? [];
    return result;
};
/** 持有部位 */
export const useVirtualAccountHolding = (linkedName) => {
    const id = useVirtualAccountLink(linkedName);
    const url = urlcat(`${prefixUrl}/${VirtualExchange.positions}`, { accountId: id ?? 0 });
    const res = useSWR(id ? url : null, fetcherWithFixedToken, {
        revalidateOnFocus: true,
        refreshInterval: 10000,
    });
    //依照日期由近排序
    const result = res.data?.sort((a, b) => Date.parse(b.lastModifiedDatetime) - Date.parse(a.lastModifiedDatetime));
    return result;
};
/** 交易查詢 */
export const useVirtualTransaction = (linkedName, options, config) => {
    const { symbol, beginDatetime, endDatetime, limit, orderBy } = options;
    const id = useVirtualAccountLink(linkedName);
    const url = urlcat(`${prefixUrl}/${VirtualExchange.transactions}`, {
        accountId: id ?? 0,
        symbol: symbol,
        beginDatetime: beginDatetime,
        endDatetime: endDatetime,
        limit: limit,
        orderBy: orderBy,
    });
    const res = useSWR(id ? url : null, fetcherWithFixedToken, {
        revalidateOnFocus: false,
        refreshInterval: config?.refreshInterval ?? 0,
    });
    //依照日期由近排序
    const result = res.data?.sort((a, b) => Date.parse(b.datetime) - Date.parse(a.datetime));
    return result;
};
/** 交易查詢 */
export const useVirtualTransactionStatement = (linkedName, options, config) => {
    const { symbol, beginDatetime, endDatetime, limit, orderBy } = options;
    const id = useVirtualAccountLink(linkedName);
    const url = urlcat(`${prefixUrl}/${VirtualExchange.transactionsStatements}`, {
        accountId: id ?? 0,
        symbol: symbol,
        beginDatetime: beginDatetime,
        endDatetime: endDatetime,
        limit: limit,
    });
    const res = useSWR(id ? url : null, fetcherWithFixedToken, {
        revalidateOnFocus: false,
        refreshInterval: config?.refreshInterval ?? 0,
    });
    //依照日期由近排序
    const result = res.data?.sort((a, b) => Date.parse(b.datetime) - Date.parse(a.datetime));
    return result;
};
/** 即將進場的交易商品 */
export const useVirtualAccountOrderPre = (linkedName, bs, options) => {
    const dateTime = options?.dateTime;
    const id = useVirtualAccountLink(linkedName);
    const url = urlcat(`${prefixUrl}/${VirtualExchange.preOrders}`, {
        accountId: id ?? 0,
        dateTime: dateTime,
    });
    const res = useSWR(id ? url : null, fetcherWithFixedToken, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    const result = res.data?.filter(item => item.bs === bs);
    return result;
};
/** 目前使用者底下所有帳戶 */
export const useVirtualAccountAllPrivateAccounts = (token) => {
    const url = `${prefixUrl}/${VirtualExchange.getUserAllAccounts}`;
    const res = useSWR([token ? url : null, token], fetcherWithToken, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return res.data ?? [];
};
/** 公開使用者 `fixedtoken`，這個`假人`下面底下的帳戶 */
export const useVirtualAccountAllPublicAccounts = () => {
    const url = `${prefixUrl}/${VirtualExchange.getUserAllAccounts}`;
    const res = useSWR(url, fetcherWithFixedToken, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return res.data ?? [];
};
/** Private or Public 帳戶每日損益變化 */
export const useVirtualAccountDailyProfitByAccountId = (accountId, token, useSelfToken) => {
    const url = urlcat(`${prefixUrl}/${VirtualExchange.dailyProfit}`, {
        accountId: accountId ? accountId : 0,
    });
    const res = useSWR(useSelfToken ? [accountId && token ? url : null, token] : accountId ? url : null, useSelfToken ? fetcherWithToken : fetcherWithFixedToken, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    const result = res.data;
    return result;
};
const fetcherWithFixedToken = async (url) => {
    const res = await fetch(url, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer fixedtoken`,
        },
    });
    if (res.status >= 400)
        throw new Error();
    return res.json();
};
const fetcherWithToken = async (url, token) => {
    if (isEmpty(url))
        return;
    const res = await fetch(url, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    if (res.status >= 400)
        throw new Error();
    return res.json();
};
