import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const bole_kd_dark = createIndicator({
    displayName: '威力指標 [能量/趨勢/差值]',
    id: 'bolekd',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in0 = 44;
            const in1 = 4;
            const in2 = 3;
            const itime = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close_array = this._context.new_var(close);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const stoch = this.PineJS.Std.stoch(close_array, high_array, low_array, in0, this._context);
            const stoch_array = this._context.new_var(stoch);
            //高
            const vHn = this.PineJS.Std.highest(high_array, in0, this._context);
            //低
            const vLn = this.PineJS.Std.lowest(low_array, in0, this._context);
            //RSV
            const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
            const vrsv_array = this._context.new_var(vrsv);
            //K%
            const vk_array = this._context.new_var();
            const s = (1.0 / in1) * vrsv + (1 - 1.0 / in1) * vk_array.get(1);
            if (isNaN(s)) {
                vk_array.set(0);
            }
            else {
                vk_array.set(s);
            }
            //D%
            const vd_array = this._context.new_var();
            const q = (1.0 / in2) * vk_array + (1 - 1.0 / in2) * vd_array.get(1);
            if (isNaN(q)) {
                vd_array.set(0);
            }
            else {
                vd_array.set(q);
            }
            const line1_color = vk_array.get(1) - vd_array.get(1) < 0 ? 0 : 1;
            return [vk_array.get(0), vd_array.get(0), vk_array.get(0) - vd_array.get(0), line1_color];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'colorer',
                target: 'plot_1',
                palette: 'palette_0',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
            volumePalette: {
                colors: {
                    0: {
                        name: '能量',
                    },
                },
            },
        },
        defaults: {
            //樣式調整
            styles: {
                //--
                plot_0: {
                    transparency: 0,
                    visible: !0,
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    transparency: 0,
                    visible: !0,
                    color: '#ff22ff',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 0,
                    plottype: 1,
                    transparency: 100,
                    visible: !0,
                    color: '#22ffff',
                },
                //--
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#fcfd01',
                },
            },
            //填充區域
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#22ffff',
                            width: 3,
                            style: 0,
                        },
                        1: {
                            color: '#ff22ff',
                            width: 3,
                            style: 0,
                        },
                    },
                },
                volumePalette: {
                    colors: {
                        0: {
                            color: '#fcfd01',
                            width: 1,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            vol: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '趨勢',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: '差值',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
