import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { spliteIndustryGroup, useGetindustry, } from '~/pages/edwin/component/industrySelecter/PopularIndustryContent';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { SimpleQuoteBodyItem, SimpleQuoteDetailedHeader } from '../component/SimpleQuoteHeaderNBody';
export const PopularIndustry = memo(function PopularIndustry() {
    const day0 = useSnapshot(staticStore).tradedDate.day0;
    const popularSymbol = usePopularPick({ date: day0.format('YYYY-MM-DD'), sort: 'value', limit: 40 }).resultSymbol ?? [];
    //將沒有冒號的細產業去除掉 因為沒有明確分類 所以底下可能會有太多商品
    //例如: 電腦及週邊設備, 電腦及週邊設備:伺服器 => 電腦及週邊設備:伺服器
    //給以餵給 getSymbolsOfIndustry
    const popularIndustry = useGetindustry(popularSymbol).filter(s => s.replace(/\(.*?\)/g, '').split(':').length > 1);
    //選擇中的細產業
    const [singleIndustry, setSingleIndustry] = useState(popularIndustry[0]);
    const resultSymbol = fr_instrument.getSymbolsOfIndustry(singleIndustry);
    useEffect(() => {
        signalrStore2.addQuote(resultSymbol ?? ['TSEA']);
        return () => {
            signalrStore2.removeQuote(resultSymbol ?? ['TSEA']);
        };
    }, [resultSymbol]);
    useEffect(() => {
        if (singleIndustry === undefined) {
            setSingleIndustry(popularIndustry[0]);
        }
    }, [popularIndustry, singleIndustry]);
    const value = signalrHooks2.useQuotes(useSignalr2QuoteSort(resultSymbol ?? []));
    return (<styleds.container>
      <styleds.dashborad>
        {popularIndustry.map(name => {
            const selected = name === singleIndustry;
            return (<styleds.card selected={selected} key={name} onClick={() => {
                    setSingleIndustry(name ?? '');
                }}>
              <span>{spliteIndustryGroup(name)}</span>
            </styleds.card>);
        })}
      </styleds.dashborad>
      <styleds.header>
        <SimpleQuoteDetailedHeader />
      </styleds.header>
      <styleds.body>
        {value?.map(symbol => (<SimpleQuoteBodyItem key={symbol.symbol} symbol={symbol.symbol}/>))}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
    background-color: #222222;
  `,
    dashborad: styled.div `
    ${scrollbar2Css};
    ${flex.wrap.crossCenter};
    width: 100%;
    height: 140px;
    gap: 4px;
    padding: 4px;
    background-color: #323232;
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 188px);
  `,
    card: styled.div `
    ${fill_vertical_all_center};
    width: 180px;
    height: 40px;
    background-color: #454545;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid ${props => (props.selected ? '#cacaca' : 'transparent')};
    &:hover {
      background-color: #545454;
    }
  `,
};
