import { Button } from '@mantine/core';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { css } from '@emotion/react';
import { boleStock_store } from '../_private/boleStock_store';
export const ScreenerButton = memo(function ScreenerButton(props) {
    const selected = useSnapshot(boleStock_store).conditions === props.condition;
    return (<Button css={css `
          width: 100%;
          height: 32px;
          &.mantine-1432awf {
            padding-right: 4px;
            padding-left: 4px;
          }
          &.mantine-1un7m44 {
            padding-right: 4px;
            padding-left: 4px;
          }
          &.mantine-g7194 {
            padding-right: 4px;
            padding-left: 4px;
          }
          &.mantine-1xk8bhn {
            padding-right: 4px;
            padding-left: 4px;
          }
        `} variant={selected ? 'filled' : 'default'} onClick={() => (boleStock_store.conditions = props.condition)}>
        {props.children}
      </Button>);
});
