import { memo, useCallback } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { MdOutlineWbSunny, MdDarkMode } from 'react-icons/md';
import { IconButton } from '@mui/material';
export const ThemeToggle = memo(function ThemeToggle(props) {
    const theme = useThemeStore(t => t.theme);
    const toggleChecked = useCallback(() => {
        useThemeStore.setState({ theme: theme === 'light' ? 'dark' : 'light' });
    }, [theme]);
    return (<IconButton className={props.className} color='primary' size='large' onClick={event => {
            toggleChecked();
            props.onClick?.(event);
        }}>
      {theme === 'dark' ? <MdOutlineWbSunny /> : <MdDarkMode />}
    </IconButton>);
});
