import { css } from '@emotion/react';
import { Paper, createTheme } from '@mui/material';
import { useRouter } from 'next/router';
import { memo, useEffect } from 'react';
import ThemeLayout from '~/components/theme/ThemeLayout';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fontWeight600 } from '~/css/font';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { useStockToFutureMapper } from '~/modules/SDK/Symbol/useCommonSymbolList';
import useMedia from '~/hooks/useMedia';
import { boleStock_store } from '../_private/boleStock_store';
export const SimpleQuoteDetailedHeader = memo(function SimpleQuoteDetailedHeader() {
    const theme = useThemeStore(s => s.theme);
    return (<Paper css={css `
        ${flex.h.default};
        width: 100%;
        height: 40px;
        font-size: 16px;
        background-color: #3d3d3d;
        border-bottom: 1px solid ${theme === 'light' ? '#555555' : '#555555'};
        padding-right: 40px;
      `}>
      <Cell_>代碼</Cell_>
      <Cell_>名稱</Cell_>
      <Cell_>成交</Cell_>
      <Cell_>漲跌</Cell_>
      <Cell_>漲跌幅</Cell_>
      <Cell_>開盤</Cell_>
      <Cell_>最高</Cell_>
      <Cell_>最低</Cell_>
      <Cell_>昨收</Cell_>
      <Cell_>總量</Cell_>
    </Paper>);
});
const darkThemeBody = createTheme({
    palette: {
        mode: 'dark',
        background: { paper: '#000000' },
    },
});
const lightTheme = createTheme({
    palette: {
        mode: 'light',
        background: { paper: '#dddddd' },
    },
});
/** 單一頁面自選股、權值股的商品報價(寬度較寬) 點選會返回綜合報價 */
export const SimpleQuoteBodyItem = memo(function SimpleQuoteBodyItem(props) {
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const changeColor = quoteChanges.closeChange >= 0 ? '#fb0000' : '#01c200';
    const changeArrow = quoteChanges.closeChange >= 0 ? '▲' : '▼';
    const theme = useThemeStore(s => s.theme);
    const router = useRouter();
    const { isPhone } = useMedia();
    useEffect(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
        return () => {
            useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
        };
    }, [props.symbol]);
    const futuresDisctionary = useStockToFutureMapper();
    return (<ThemeLayout lightTheme={lightTheme} darkTheme={darkThemeBody}>
        <Paper css={css `
            ${rowCss};
            &:hover {
              background-color: ${theme === 'dark' ? '#111111' : '#bbbbbb'};
              transition: 0.1s;
            }
          `} onClick={() => {
            boleStock_store.userLastFocusSymbol = props.symbol;
            router.replace('/bole_stock');
        }}>
          <Cell_ color={isPhone && futuresDisctionary?.[props.symbol] ? '#ffee00' : undefined}>
            {props.symbol}&nbsp;
            <span css={css `
                position: absolute;
                right: 8px;
              `}>
              {!isPhone && futuresDisctionary?.[props.symbol] ? '🌟' : ''}
            </span>
          </Cell_>
          <Cell_>
            <SymbolName symbol={props.symbol}/>
          </Cell_>
          <Cell_ color={changeColor}>
            {changeArrow}
            {symbolInfo?.close ?? '-'}
          </Cell_>
          <Cell_ color={changeColor}>
            {symbolInfo ? (quoteChanges.closeChange >= 0 ? '+' : '') : ''}
            {symbolInfo ? quoteChanges.closeChange : '-'}
          </Cell_>
          <Cell_ color={changeColor}>
            {symbolInfo ? quoteChanges.closeChangePercentString : '-'}
          </Cell_>
          <Cell_ color={changeColor}>{symbolInfo?.open ?? '-'}</Cell_>
          <Cell_ color={changeColor}>{symbolInfo?.high ?? '-'}</Cell_>
          <Cell_ color={changeColor}>{symbolInfo?.low ?? '-'}</Cell_>
          <Cell_ color={theme === 'dark' ? '#eeeeee' : '#222222'}>
            {symbolInfo?.prevRef ?? '-'}
          </Cell_>
          <Cell_ color={theme === 'dark' ? '#ffee00' : '#6655ee'}>
            {symbolInfo?.volume ?? '-'}
          </Cell_>
        </Paper>
      </ThemeLayout>);
});
const rowCss = css `
  ${flex.h.default};
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  ${fontWeight600};
  margin-bottom: 4px;
`;
const Cell_ = memo(function Cell_(props) {
    return (<div css={css `
        position: relative;
        ${cellCss};
        color: ${props.color};
        border-radius: 0;
      `}>
      {props.children}
    </div>);
});
const cellCss = css `
  text-align: center;
  line-height: 40px;
  width: 10%;
  height: 100%;
`;
