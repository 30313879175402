import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 移動平均線扣抵 */
export const bole_deduction = createIndicator({
    displayName: '均線扣抵',
    id: 'bolededuction',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in1 = 5;
            const symbol = this._context.symbol.info?.full_name;
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            itime_array.get(5);
            let result = NaN;
            const last_bar = this._context.symbol.isLastBar;
            const new_bar = this._context.symbol.isNewBar;
            const close_bar = this._context.symbol.isBarClosed;
            const interval = this._context.symbol?.periodBase;
            const symbol_display = (last_bar === true && new_bar === false && close_bar === false) ||
                (last_bar === true && new_bar === true && close_bar === false) ||
                (last_bar === true && new_bar === true && close_bar === true);
            /**狀態
             * 盤中: last_bar:true, new_bar:false, close_bar:false
             * 新開: last_bar:true, new_bar:true, close_bar:false  *還有可能是剛載入但沒有新報價
             * 日盤收: last_bar:true, new_bar:true, close_bar:true
             * --------------------------------------------------
             * 收定: last_bar:true, new_bar:false, close_bar:true
             * 換棒: last_bar:true, new_bar:false, close_bar:true
             * */
            result = symbol_display ? 1 : NaN;
            if (last_bar === true) {
                console.log({
                    symbol,
                    Last: last_bar,
                    New: new_bar,
                    Close: close_bar,
                });
            }
            return [
                {
                    value: result,
                    offset: -5 + 1,
                },
                {
                    value: result,
                    offset: -10 + 1,
                },
                {
                    value: result,
                    offset: -20 + 1,
                },
                {
                    value: result,
                    offset: -60 + 1,
                },
            ];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    color: '#912b2b',
                    textColor: '#912b2b',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#36dad8',
                    textColor: '#36dad8',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#ef319e',
                    textColor: '#ef319e',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#f47158',
                    textColor: '#f47158',
                    transparency: 0,
                    visible: true,
                },
            },
        },
        styles: {
            plot_0: {
                title: '5日扣抵',
                isHidden: false,
                location: 'BelowBar',
                char: '◆',
                size: 'small',
                text: '',
            },
            plot_1: {
                title: '20日扣抵',
                isHidden: false,
                location: 'BelowBar',
                char: '◆',
                size: 'small',
                text: '',
            },
            plot_2: {
                title: '60日扣抵',
                isHidden: false,
                location: 'BelowBar',
                char: '◆',
                size: 'small',
                text: '',
            },
            plot_3: {
                title: '120日扣抵',
                isHidden: false,
                location: 'BelowBar',
                char: '◆',
                size: 'small',
                text: '',
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
export const bole_deduction_dark = createIndicator({
    displayName: '均線扣抵',
    id: 'boledeductiondark',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in1 = 5;
            const symbol = this._context.symbol.info?.full_name;
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            itime_array.get(5);
            let result = NaN;
            const last_bar = this._context.symbol.isLastBar;
            const new_bar = this._context.symbol.isNewBar;
            const close_bar = this._context.symbol.isBarClosed;
            const interval = this._context.symbol?.periodBase;
            const symbol_display = (last_bar === true && new_bar === false && close_bar === false) ||
                (last_bar === true && new_bar === true && close_bar === false) ||
                (last_bar === true && new_bar === true && close_bar === true);
            /**狀態
             * 盤中: last_bar:true, new_bar:false, close_bar:false
             * 新開: last_bar:true, new_bar:true, close_bar:false  *還有可能是剛載入但沒有新報價
             * 日盤收: last_bar:true, new_bar:true, close_bar:true
             * --------------------------------------------------
             * 收定: last_bar:true, new_bar:false, close_bar:true
             * 換棒: last_bar:true, new_bar:false, close_bar:true
             * */
            result = symbol_display ? 1 : NaN;
            if (last_bar === true) {
                console.log({
                    symbol,
                    Last: last_bar,
                    New: new_bar,
                    Close: close_bar,
                });
            }
            return [
                {
                    value: result,
                    offset: -5 + 1,
                },
                {
                    value: result,
                    offset: -10 + 1,
                },
                {
                    value: result,
                    offset: -20 + 1,
                },
                {
                    value: result,
                    offset: -60 + 1,
                },
            ];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    color: '#cccc00',
                    textColor: '#cccc00',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#00c1c1',
                    textColor: '#00c1c1',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#b600b6',
                    textColor: '#b600b6',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#00ff77',
                    textColor: '#00ff77',
                    transparency: 0,
                    visible: true,
                },
            },
        },
        styles: {
            plot_0: {
                title: '5日扣抵',
                isHidden: false,
                location: 'BelowBar',
                char: '◆',
                size: 'small',
                text: '',
            },
            plot_1: {
                title: '20日扣抵',
                isHidden: false,
                location: 'BelowBar',
                char: '◆',
                size: 'small',
                text: '',
            },
            plot_2: {
                title: '60日扣抵',
                isHidden: false,
                location: 'BelowBar',
                char: '◆',
                size: 'small',
                text: '',
            },
            plot_3: {
                title: '120日扣抵',
                isHidden: false,
                location: 'BelowBar',
                char: '◆',
                size: 'small',
                text: '',
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
