import styled from '@emotion/styled';
import { memo, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { useStockWeightResource } from '~/modules/chips/useChipResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { SimpleQuoteBodyItem, SimpleQuoteDetailedHeader } from '../component/SimpleQuoteHeaderNBody';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
export const HighWeightSymbolList = memo(function HighWeightSymbolList() {
    const twse = useStockWeightResource('TWSE');
    const twse50 = useSnapshot(staticStore.symbol.tw50).map(s => s);
    const orderedTWSEStocks = useMemo(() => {
        return twse
            .filter(([k]) => twse50?.indexOf(k) !== -1)
            .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
            .sort((a, b) => b.percentage - a.percentage);
    }, [twse, twse50]);
    const twse50Number = orderedTWSEStocks.map(item => item.symbol).slice(0, 19);
    return (<styleds.container>
      <styleds.header>
        <SimpleQuoteDetailedHeader />
      </styleds.header>
      <styleds.body>
        {twse50Number?.map(symbol => (<SimpleQuoteBodyItem key={symbol} symbol={symbol}/>))}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
    background-color: #222222;
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 40px);
  `,
};
