import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import KbarSymbolQuote from '../quote/KbarSymbolQuote';
import { edwinGroupName } from '../WatchListGroupName';
import styled from '@emotion/styled';
export const useGetindustry = (symbols) => {
    //餵商品清單近來,他會自動幫你找出這些商品的細產業,然後返回一個細產業陣列給你
    //細產業 先拿到各項商品的細產業
    const industryArrayGroup = symbols.map(symbol => {
        const symbolIndustry = fr_instrument.getIndustryBySymbol(symbol) ?? [];
        return symbolIndustry;
    });
    // 組合陣列
    const industryArray = industryArrayGroup.flatMap(symbolIndustry => symbolIndustry);
    // 紀錄陣列項目的出現次数
    const countMap = new Map();
    industryArray.forEach(item => {
        countMap.set(item, (countMap.get(item) || 0) + 1);
    });
    // 過濾掉只出現一次的細產業
    const filterOnceIndustry = industryArray.filter(item => countMap.get(item) > 2);
    //刪除重複的細產業
    const uniqueSet = new Set(filterOnceIndustry);
    const uniqueArray = [...uniqueSet];
    return uniqueArray;
};
//將沒有明確定義的細產業移除 例如: 電腦及週邊設備, 電腦及週邊設備:伺服器 => 電腦及週邊設備:伺服器 //顯示給人看的
export const spliteIndustryGroup = (industryName) => {
    //先移除奇奇怪怪的括號或符號
    const splitName = industryName.replace(/\(.*?\)/g, '').split(':');
    //有冒號則用冒號後的細產業 例如: 電腦及週邊設備:伺服器 => 伺服器
    const newIndustry = splitName.length > 1 ? splitName[1].split('、')[0] : splitName[0].split('、')[0];
    const name = newIndustry.replace('_', ': ');
    return name;
};
export const PopularIndustryContent = memo(function PopularIndustryContent() {
    const day0 = useSnapshot(staticStore).tradedDate.day0;
    const popularSymbol = usePopularPick({ date: day0.format('YYYY-MM-DD'), sort: 'value', limit: 30 }).resultSymbol ?? [];
    //將沒有冒號的細產業去除掉 因為沒有明確分類 所以底下可能會有太多商品
    //例如: 電腦及週邊設備, 電腦及週邊設備:伺服器 => 電腦及週邊設備:伺服器
    //給以餵給 getSymbolsOfIndustry
    const popularIndustry = useGetindustry(popularSymbol).filter(s => s.replace(/\(.*?\)/g, '').split(':').length > 1);
    //選擇中的細產業
    const [singleIndustry, setSingleIndustry] = useState(popularIndustry[0]);
    const resultSymbol = fr_instrument.getSymbolsOfIndustry(singleIndustry) ?? [];
    useEffect(() => {
        signalrStore2.addQuote(resultSymbol ?? ['TSEA']);
        return () => {
            signalrStore2.removeQuote(resultSymbol ?? ['TSEA']);
        };
    }, [JSON.stringify(resultSymbol)]);
    useEffect(() => {
        if (singleIndustry === undefined) {
            setSingleIndustry(popularIndustry[0]);
        }
    }, [popularIndustry, singleIndustry]);
    const value = signalrHooks2.useQuotes(useSignalr2QuoteSort(resultSymbol, 2));
    return (<styleds.container>
      <styleds.industryGroup>
        {popularIndustry.map(name => {
            const selected = name === singleIndustry;
            return (<styleds.industryCard selected={selected} key={name} onClick={() => {
                    setSingleIndustry(name ?? '');
                }}>
              <styleds.cardText>{spliteIndustryGroup(name)}</styleds.cardText>
            </styleds.industryCard>);
        })}
      </styleds.industryGroup>
      <styleds.symbolListContent>
        <KbarSymbolQuote.header sortGroup={2}></KbarSymbolQuote.header>
        <styleds.list>
          {value?.map((quote, index) => {
            return (<KbarSymbolQuote.body groupName={edwinGroupName} key={index} quote={quote}/>);
        })}
        </styleds.list>
      </styleds.symbolListContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    industryCard: styled.div `
    ${fill_horizontal_all_center};
    width: calc(50% - 2px);
    height: 30px;
    border-radius: 4px;
    background-color: #2e323b;
    cursor: pointer;
    border: 1px solid ${props => (props.selected === true ? '#ffffff' : 'transparent')};
    flex-shrink: 0;
    overflow: hidden;
    padding: 0 4px;
    &:hover {
      background-color: #373c46;
      transition: 0.3s;
    }
  `,
    cardText: styled.div `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
  `,
    industryGroup: styled.div `
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    height: 132px;
    padding: 2px;

    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #555;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      height: 4px;
      border-radius: 5px;
      background-color: #ccc;
    }
  `,
    symbolListContent: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 136px);
    gap: 4px;
  `,
    list: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 30px);
    gap: 2px;
  `,
};
