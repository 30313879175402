import { Button } from '@mui/material';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { AppTooltip } from '~/components/AppTooltip';
import { symbolGroupStore } from '~/modules/investment-consultant/SymbolGroupTable/symbolGroupStore';
/** 依成交量篩選模式切換鈕 */
export const SymbolGroupVolumeFilterSwitch = memo(function SymbolGroupVolumeFilterSwitch(props) {
    const symbolGroupState = useSnapshot(symbolGroupStore);
    return (<AppTooltip title={(symbolGroupState.volumeLimitModeIsGT
            ? `顯示成交量大於`
            : symbolGroupState.volumeLimitModeIsLT
                ? '顯示成交量小於'
                : '') + `${symbolGroupState.volumeLimit}`}>
        <Button size='small' variant='contained' onClick={event => {
            symbolGroupStore.volumeLimitMode = symbolGroupStore.volumeLimitMode === '>' ? '<' : '>';
        }} className={props.className} {...props}>
          {symbolGroupState.volumeLimitMode}
        </Button>
      </AppTooltip>);
});
