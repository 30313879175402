import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';
import { SimpleQuoteBodyItem, SimpleQuoteDetailedHeader } from '../component/SimpleQuoteHeaderNBody';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { BsFillPlusCircleFill, BsSearch, BsXSquareFill } from 'react-icons/bs';
import { css } from '@emotion/react';
import { SymbolSearch } from '~/modules/SDK/Symbol/SymbolSearch';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { StockChangeBar } from '../component/StockChangeBar';
export const WatchListSymbolList = memo(function WatchListSymbolList() {
    const { state: watchListState, acts: watchListActs } = useSymbolWatchList('bole_stock_group_1');
    const watchlistSymbol = watchListState.symbolListArray;
    useEffect(() => {
        signalrStore2.addQuote(watchlistSymbol);
        return () => {
            signalrStore2.removeQuote(watchlistSymbol);
        };
    }, [JSON.stringify(watchlistSymbol)]);
    const sortData = useSignalr2QuoteSort(watchlistSymbol) ?? [];
    const value = signalrHooks2.useQuotes(sortData);
    return (<styleds.container>
      <styleds.searchContent>
        <WatchListAddRow acts={watchListActs} watchListValue={value}/>
      </styleds.searchContent>

      <styleds.header>
        <SimpleQuoteDetailedHeader />
      </styleds.header>
      <styleds.body>
        {watchListState.symbolListArray?.map(symbol => (<SymbolBody key={symbol} acts={watchListActs} symbol={symbol}/>))}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
    background-color: #222222;
    gap: 4px;
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 100px);
    padding: 4px;
    gap: 4px;
  `,
    searchContent: styled.div `
    ${fill_vertical_all_center};
    height: 100px;
  `,
};
const SymbolBody = memo(function SymbolBody(props) {
    const conrainer = css `
    display: grid;
    grid-template-columns: calc(100% - 32px) 32px;
    width: 100%;
    height: 40px;
  `;
    return (<div css={conrainer}>
      <div css={css `
          ${fill_horizontal_cross_center};
        `}>
        <SimpleQuoteBodyItem symbol={props.symbol}/>
      </div>
      <div css={css `
          ${fill_horizontal_all_center};
        `} onClick={() => props.acts.removeSymbol(props.symbol)}>
        <BsXSquareFill />
      </div>
    </div>);
});
const WatchListAddRow = memo(function WatchListAddRow(props) {
    const [symbol, setSymbol] = useState('TX-1');
    const handleSelectChange = (newValue) => {
        setSymbol(newValue.symbol);
    };
    const container = css `
      display: grid;
      grid-template-row: 60px 40px;
      width: 100%;
      height: 100px;
      background-color: #464646;
      border-radius: 4px;
      gap: 4px;
      padding: 4px;
    `;
    const dashboard = css `
      ${fill_horizontal_cross_center};
      gap: 8px;
    `;
    const addbutton = css `
      ${fill_horizontal_all_center};
      width: 224px;
      height: 40px;
      background-color: #ccaa00;
      border-radius: 4px;
    `;
    const stockChangeBar = css `
      width: 300px;
      height: 40px;
    `;
    return (<div css={container}>
        <div css={dashboard}>
          <span>
            <BsSearch />
            &nbsp;搜尋股票
          </span>
          <SymbolSearch onChangeCb={handleSelectChange} variant='outlined'/>
          <div css={addbutton} onClick={() => props.acts.addSymbol(symbol, 30)}>
            <BsFillPlusCircleFill /> 新增 {symbol} 至自選股
          </div>
          <div css={stockChangeBar}>
            <StockChangeBar value={props.watchListValue}/>
          </div>
        </div>
        <SimpleQuoteBodyItem symbol={symbol}/>
      </div>);
});
