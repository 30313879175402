import { css } from '@emotion/react';
import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { SimpleQuoteBodyItem, SimpleQuoteDetailedHeader } from '../component/SimpleQuoteHeaderNBody';
import styled from '@emotion/styled';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { Button } from '@mantine/core';
import { useExtremeVolumeResource, useTurnoverRateResource, } from '~/modules/screener/containers/useStockScreenerResource';
const turnoverEmptyData = [
    {
        date: '',
        symbol: '',
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        volume: 0,
        avg_turnover_rate: 0,
        turnover_rate: 0,
        issued_shares: 0,
    },
];
const extremeVolumeEmptyData = [
    {
        date: '',
        symbol: '',
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        volume: 0,
        volume_ma: 0,
    },
];
export const PopularStock = memo(function PopularStock() {
    const date0 = useSnapshot(staticStore).tradedDate.day0;
    //熱門
    const popularData = usePopularPick({
        date: date0.format('YYYY-MM-DD'),
        sort: 'value',
        limit: 30,
    }).resultSymbol;
    //週轉
    const turnoverRateData = useTurnoverRateResource({
        date: date0.format('YYYY-MM-DD'),
        days: 7,
        threshold: 0.1,
        volume_gte: 1e3,
        volume_lte: 1e10,
        amount_gte: 1e5,
        amount_lte: 1e10,
    }).data ?? turnoverEmptyData;
    //成交量
    const extremeVolumeData = useExtremeVolumeResource({
        date: date0.format('YYYY-MM-DD'),
        days: 7,
        volume_gte: 1e4,
        volume_lte: 1e10,
        amount_gte: 1e6,
        amount_lte: 1e10,
        threshold: 1.25,
    }).data ?? extremeVolumeEmptyData;
    const popularSymbol = popularData?.map(s => s);
    const turnoverSymbol = turnoverRateData.map(s => s.symbol);
    const extremeSymbol = extremeVolumeData.map(s => s.symbol);
    const [data, setData] = useState(popularData);
    const [type, setType] = useState('popularSymbol');
    useEffect(() => {
        if (data === undefined) {
            setData(popularData);
        }
    }, [data?.length, popularData]);
    return (<styleds.container>
      <div css={css `
          ${fill_horizontal_cross_center};
          height: 48px;
          gap: 8px;
          background-color: #323232;
          border-radius: 4px;
          padding: 0 8px;
        `}>
        <span>排行選項</span>
        <Button css={css `
            width: 132px;
            height: 32px;
          `} onClick={() => {
            setData(popularSymbol);
            setType('popularSymbol');
        }} color={'yellow'} variant={type === 'popularSymbol' ? 'filled' : 'default'}>
          當日熱門排行
        </Button>
        <Button css={css `
            width: 132px;
            height: 32px;
          `} onClick={() => {
            setData(turnoverSymbol);
            setType('turnoverSymbol');
        }} color={'yellow'} variant={type === 'turnoverSymbol' ? 'filled' : 'default'}>
          近期一週熱門
        </Button>
        <Button css={css `
            width: 132px;
            height: 32px;
          `} onClick={() => {
            setData(extremeSymbol);
            setType('extremeSymbol');
        }} color={'yellow'} variant={type === 'extremeSymbol' ? 'filled' : 'default'}>
          近期一週量增
        </Button>
      </div>
      <styleds.header>
        <SimpleQuoteDetailedHeader />
      </styleds.header>
      <styleds.body>
        {data?.map(symbol => (<SimpleQuoteBodyItem key={symbol} symbol={symbol}/>))}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
    background-color: #222222;
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 98px);
  `,
};
