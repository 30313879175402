import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_cross_center, fill_vertical_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { ToolTipSmallChart } from '~/modules/investment-consultant/symbolTrendChart/SymbolTrendChart/ToolTipSmallChart';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
const industry = [
    'TSECEM',
    'TSEFOOD',
    'TSEPLAS',
    'TSECON',
    'TSEAUTO',
    'TSEOTHR',
    'TSETEXT',
    'TSEECI',
    'TSEMACH',
    'TSEEAW',
    'TSEBMC',
    'TSECHI',
    'TSEGLP',
    'TSECPP',
    'TSESTEE',
    'TSERUB',
    'TSETRAN',
    'TSECPE',
    'TSESCI',
    'TSEOEI',
    'TSECII',
    'TSEOPE',
    'TSEEPD',
    'TSEISI',
    'TSEDEPT',
    'TSEOEG',
    'TSE17',
];
export const CategoryTrendChart = memo(function CategoryTrendChart() {
    useEffect(() => {
        signalrStore2.addQuote(industry);
        return () => {
            signalrStore2.removeQuote(industry);
        };
    }, []);
    const data = signalrHooks2.useQuotes(useSignalr2QuoteSort(industry));
    const [type, setType] = useState('volume');
    return (<styleds.container>
      <styleds.dashboard>
        <span>排序方式</span>
        <Button css={css `
            width: 132px;
            height: 32px;
          `} onClick={() => {
            useSortSignalrDataStore.sortKey = 'changePrecent';
            setType('changePrecent');
        }} color={'yellow'} variant={type === 'changePrecent' ? 'filled' : 'default'}>
          漲幅
        </Button>
        <Button css={css `
            width: 132px;
            height: 32px;
          `} onClick={() => {
            useSortSignalrDataStore.sortKey = 'volume';
            setType('volume');
        }} color={'yellow'} variant={type === 'volume' ? 'filled' : 'default'}>
          成交值
        </Button>
      </styleds.dashboard>
      <styleds.symbolList>
        <styleds.listContnet>
          {data.map(symbol => {
            return (<ToolTipSmallChart symbol={symbol.symbol} key={symbol.symbol}/>);
        })}
        </styleds.listContnet>
      </styleds.symbolList>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
  `,
    dashboard: styled.div `
    ${fill_horizontal_cross_center};
    height: 48px;
    gap: 8px;
    background-color: #323232;
    border-radius: 4px;
    padding: 0 8px;
  `,
    symbolList: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 48px);
  `,
    listContnet: styled.div `
    ${scrollbar2Css};
    ${flex.wrap.crossCenter};
    width: 100%;
    height: 100%;
    padding: 4px;
    gap: 8px;
  `,
};
