import { createStore } from '~/store/createStore';
export const useTradeStore = createStore((set, get) => {
    return {
        brokerType: 'Brokerless',
        accounts: [],
        publicAccounts: [],
        currentSelectAccountId: undefined,
        currentSelectAccountBalance: undefined,
        currentSelectAccountEquity: undefined,
        currentSelectAccountUnrealizedPnL: undefined,
        user: undefined,
        hasTradeUser: false,
        positions: [],
        transactions: [],
        pendingOrders: [],
        orders: [],
        dailyProfit: [],
        statements: [],
        account: '',
        balance: 0,
        qty: 1,
        price: 'M',
        /**
         * 以下是 https://strategy-center.e01.futures-op.com/swagger/index.html 多出來的部分
         *
         * 上面多為重複，所以直接沿用 useTradeStore
         */
        strategyAuth: undefined,
        strategyAuthUserName: undefined,
        strategies: [],
        currentStrategyName: undefined,
    };
});
