import { css } from '@emotion/react';
import React, { memo } from 'react';
import { useSnapshot } from 'valtio';
import { symbolGroupStore } from '~/modules/investment-consultant/SymbolGroupTable/symbolGroupStore';
import { Paper, TextField } from '@mui/material';
import { SymbolGroupVolumeFilterSwitch } from '~/modules/investment-consultant/SymbolGroupTable/SymbolGroupVolumeFilterSwitch';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useThemeStore } from '~/components/theme/useThemeStore';
export const VolumeFilter = memo(function VolumeFilter() {
    const theme = useThemeStore(s => s.theme);
    const symbolGroupState = useSnapshot(symbolGroupStore);
    return (<Paper css={css `
        ${flex.h.crossCenter};
        width: 100%;
        height: 40px;
        color: ${theme === 'dark' ? '#ffffff' : '#222222'};
        background-color: ${theme === 'dark' ? '#444444' : '#eeeeee'};
        border-radius: 5px;
        gap: 16px;
        padding: 4px 16px;
      `}>
      <div css={css `
          ${flex.h.allCenter};
          letter-spacing: -2px;
        `}>
        成交量篩選
      </div>
      <div css={css `
          ${flex.h.allCenter};
          width: 64px;
        `}>
        <SymbolGroupVolumeFilterSwitch />
      </div>
      <div css={css `
          ${flex.h.allCenter};
          flex: 1;
        `}>
        <TextField type={'number'} variant={'standard'} value={symbolGroupState.volumeLimit} onChange={event => {
            const value = Number(event.target.value);
            if (value >= 0)
                symbolGroupStore.volumeLimit = value;
        }}/>
      </div>
      <div> 張</div>
    </Paper>);
});
